import './App.css';
import axios from "axios";
import ReactPlayer from 'react-player/file'
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { useHash } from 'react-use';
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

const queryClient = new QueryClient();
const sessionId = uuidv4();


function App() {
  const [channelId] = useHash();
  console.log('hey uuid', sessionId);

  return (
    <div className='embed-container'>
      <QueryClientProvider client={queryClient}>
        <Player channelId={channelId} sessionId={sessionId}/>
      </QueryClientProvider>
    </div>
  );
}

async function getStreamInfo(channelId, sessionId) {
  var query = '';
  console.log(sessionId)
  console.log(channelId)
  var hourLimitText = '';
  if (channelId.indexOf("?") >= 0) {
    console.log('hey');
    query = channelId.substring(channelId.indexOf("?") + 1);
    console.log('query: ', query);
    const params = new URLSearchParams(query);
    console.log('params: ', params);
    var queryParams = params.has('hourLimit');
    console.log('queryParams: ', queryParams);
    var hourLimit = params.get('hourLimit');
    console.log('hourLimit: ', hourLimit);
    hourLimitText = queryParams ? `&hourLimit=${hourLimit}` : '';
    console.log('hourLimitText: ', hourLimitText);
  }
  //var urlBase = "http://localhost:5155";
  var urlBase = "https://api.godresource.com";
  var url = `${urlBase}/api/Streams/Latest?sessionId=${encodeURI(sessionId)}&channelName=${channelId.replace(query, '').replace(/\W/g, '') + '&' + query}`;
  console.log(url);
  const { data } = await axios.get(
    url,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Max-Age': 600
      }
    },
  );
  return data;
}

function useStreamInfo(channelId, sessionId) {
  return useQuery(['streamInfo', channelId], () => getStreamInfo(channelId, sessionId), {
    enabled: !!channelId,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
  });
}

function Player({ channelId, sessionId }) {
  const { data } = useStreamInfo(channelId, sessionId);
  const [streamUrl, setStreamUrl] = useState();

  useEffect((streamUrl) => {
    if (data && data.streamUrl !== streamUrl) {
      // only update stream URL when changed.
      setStreamUrl(data.streamUrl);
    }
  }, [data]);

  return (
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        url={streamUrl}
        controls={true}
        width='100%'
        height='100%'
      />
    </div>
  );
}

export default App;
